// src/components/ProductModal.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import pb from '../pocketbaseClient';

const ProductModal = ({ isOpen, onRequestClose, product, onSave }) => {
    const [formData, setFormData] = useState({
        nume: '',
        descriere_c1: '',
        descriere_c2: '',
        descriere_e: '',
        model_code: '',
        pret: '',
        pret_nou: '',
        f_n1: '',
        f_d1: '',
        f_n2: '',
        f_d2: '',
        f_n3: '',
        f_d3: '',
        f_n4: '',
        f_d4: '',
        f_n5: '',
        f_d5: '',
        f_n6: '',
        f_d6: '',
    });

    // Reset form data when the modal is opened for adding a new product
    useEffect(() => {
        if (product) {
            // Populate form data when editing a product
            setFormData({
                nume: product.nume || '',
                descriere_c1: product.descriere_c1 || '',
                descriere_c2: product.descriere_c2 || '',
                descriere_e: product.descriere_e || '',
                pret: product.pret || '',
                pret_nou: product.pret_nou || '',
                model_code: product.model_code || '',
                f_n1: product.f_n1 || '',
                f_d1: product.f_d1 || '',
                f_n2: product.f_n2 || '',
                f_d2: product.f_d2 || '',
                f_n3: product.f_n3 || '',
                f_d3: product.f_d3 || '',
                f_n4: product.f_n4 || '',
                f_d4: product.f_d4 || '',
                f_n5: product.f_n5 || '',
                f_d5: product.f_d5 || '',
                f_n6: product.f_n6 || '',
                f_d6: product.f_d6 || '',
            });
        } else {
            // Clear form data when adding a new product
            setFormData({
                nume: '',
                descriere_c1: '',
                descriere_c2: '',
                descriere_e: '',
                pret: '',
                pret_nou: '',
                model_code: '',
                f_n1: '',
                f_d1: '',
                f_n2: '',
                f_d2: '',
                f_n3: '',
                f_d3: '',
                f_n4: '',
                f_d4: '',
                f_n5: '',
                f_d5: '',
                f_n6: '',
                f_d6: '',
            });
        }
    }, [product]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        try {
            if (product && product.id) {
                // Update existing product
                await pb.collection('produse').update(product.id, formData);
            } else {
                // Create new product
                await pb.collection('produse').create(formData);
            }
            onSave();
            onRequestClose();
        } catch (error) {
            console.error('Error saving product:', error);
        }
    };

    return (
        <Modal ariaHideApp={false} isOpen={isOpen} onRequestClose={onRequestClose} overlayClassName={'bg-gray-900 bg-opacity-50 fixed inset-0'} className="p-4 bg-white rounded shadow-lg w-1/3 h-full right-0 absolute overflow-y-scroll">
            <h2 className="text-xl mb-4">{product ? 'Edit Product' : 'Add Product'}</h2>
            <form className="flex flex-col gap-4 relative">
                <div>
                    <label htmlFor="nume" className="block text-sm font-medium text-gray-700">
                        Nume
                    </label>
                    <input
                        id="nume"
                        name="nume"
                        placeholder="Name"
                        value={formData.nume}
                        onChange={handleChange}
                        className="mt-1 border p-2 w-full"
                    />
                </div>

                <div>
                    <label htmlFor="descriere_c1" className="block text-sm font-medium text-gray-700">
                        Descriere stanga
                    </label>
                    <textarea
                        id="descriere_c1"
                        name="descriere_c1"
                        placeholder="Description C1"
                        value={formData.descriere_c1}
                        onChange={handleChange}
                        className="mt-1 border p-2 w-full h-32"
                    />
                </div>

                <div>
                    <label htmlFor="descriere_c2" className="block text-sm font-medium text-gray-700">
                        Descriere dreapta
                    </label>
                    <textarea
                        id="descriere_c2"
                        name="descriere_c2"
                        placeholder="Description C2"
                        value={formData.descriere_c2}
                        onChange={handleChange}
                        className="mt-1 border p-2 w-full h-32"
                    />
                </div>

                <div>
                    <label htmlFor="descriere_e" className="block text-sm font-medium text-gray-700">
                        Descriere extra
                    </label>
                    <input
                        id="descriere_e"
                        name="descriere_e"
                        placeholder="Description E"
                        value={formData.descriere_e}
                        onChange={handleChange}
                        className="mt-1 border p-2 w-full"
                    />
                </div>

                <div>
                    <label htmlFor="pret" className="block text-sm font-medium text-gray-700">
                        Pret
                    </label>
                    <input
                        id="pret"
                        name="pret"
                        type="text"
                        placeholder="Price"
                        value={formData.pret}
                        onChange={handleChange}
                        className="mt-1 border p-2 w-full"
                    />
                </div>

                <div>
                    <label htmlFor="pret_nou" className="block text-sm font-medium text-gray-700">
                        Pret redus
                    </label>
                    <input
                        id="pret_nou"
                        name="pret_nou"
                        type="text"
                        placeholder="New Price"
                        value={formData.pret_nou}
                        onChange={handleChange}
                        className="mt-1 border p-2 w-full"
                    />
                </div>

                <div>
                    <label htmlFor="model_code" className="block text-sm font-medium text-gray-700">
                        Cod produs
                    </label>
                    <input
                        id="model_code"
                        name="model_code"
                        placeholder="Model Code"
                        value={formData.model_code}
                        onChange={handleChange}
                        className="mt-1 border p-2 w-full"
                    />
                </div>

                {[...Array(6)].map((_, index) => (
                    <div key={index} className="gap-2 flex flex-col">
                        <div>
                            <label htmlFor={`f_n${index + 1}`} className="block text-sm font-medium text-gray-700">
                                Nume functie {index + 1}
                            </label>
                            <input
                                id={`f_n${index + 1}`}
                                name={`f_n${index + 1}`}
                                placeholder={`Feature ${index + 1} Name`}
                                value={formData[`f_n${index + 1}`]}
                                onChange={handleChange}
                                className="mt-1 border p-2 w-full"
                            />
                        </div>

                        <div>
                            <label htmlFor={`f_d${index + 1}`} className="block text-sm font-medium text-gray-700">
                                Descriere functie {index + 1}
                            </label>
                            <input
                                id={`f_d${index + 1}`}
                                name={`f_d${index + 1}`}
                                placeholder={`Feature ${index + 1} Description`}
                                value={formData[`f_d${index + 1}`]}
                                onChange={handleChange}
                                className="mt-1 border p-2 w-full"
                            />
                        </div>
                    </div>
                ))}
                <div className='sticky -bottom-4 w-full bg-white'>

                    <button type="button" onClick={handleSubmit} className="bg-blue-500 text-white p-2 my-2 rounded w-full">
                        Salveaza
                    </button>
                </div>
            </form>

        </Modal>
    );
};

export default ProductModal;
