import React, { useState } from 'react';

const ProductTable = ({ products, onSelectProduct, onEditProduct, onDeleteProduct, onCloneProduct }) => {
    const [searchTerm, setSearchTerm] = useState('');

    // Filter products based on the search term
    const filteredProducts = products.filter((product) =>
        product.nume.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.model_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.pret.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.pret_nou.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="">

            <div className="my-2">
                <input
                    type="text"
                    placeholder="Cauta produs..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="border p-2 w-[200px] rounded-md"
                />
            </div>

            <div className="mt-2 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                        Nume
                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                        Cod produs
                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                        Pret
                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                        Pret redus
                                    </th>
                                    <th
                                        scope="col"
                                        className="relative whitespace-nowrap py-3.5 pl-3 pr-4 text-right text-sm font-semibold sm:pr-0"
                                    >

                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {filteredProducts.map((product) => (
                                    <tr key={product.id} className="hover:bg-gray-50 cursor-pointer">
                                        <td
                                            className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                                            onClick={() => onSelectProduct(product)}
                                        >
                                            {product.nume}
                                        </td>
                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{product.model_code}</td>
                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{product.pret}</td>
                                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{product.pret_nou}</td>
                                        <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                            <button
                                                onClick={() => onEditProduct(product)}
                                                className="text-indigo-600 hover:text-indigo-900 "
                                            >
                                                Edit
                                            </button>
                                            <button
                                                onClick={() => onDeleteProduct(product.id)}
                                                className="text-red-600 hover:text-red-900 mx-4"
                                            >
                                                Delete
                                            </button>
                                            <button
                                                onClick={() => onCloneProduct(product)}
                                                className="text-blue-600 hover:text-blue-900"
                                            >
                                                Clone
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                {filteredProducts.length === 0 && (
                                    <tr>
                                        <td colSpan="5" className="text-center py-4 text-gray-500">
                                            No products found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductTable;
