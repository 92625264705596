// src/App.js
import React, { useState, useEffect } from 'react';
import ProductTable from './components/ProductTable';
import ProductPDFViewer from './components/ProductPDFViewer';
import ProductModal from './components/ProductModal';
import LoginModal from './components/LoginModal';
import pb, { isAuthenticated, logout } from './pocketbaseClient';

function App() {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const [products, setProducts] = useState([]);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated());

  // Fetch products from PocketBase
  const fetchProducts = async () => {
    try {
      const records = await pb.collection('produse').getFullList();
      setProducts(records);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchProducts();
    } else {
      setLoginModalOpen(true);
    }
  }, [isLoggedIn]);

  const handleSelectProduct = (product) => {
    setSelectedProduct(product);
  };

  const handleAddProduct = () => {
    setProductToEdit(null);
    setModalOpen(true);
  };

  const handleEditProduct = (product) => {
    setProductToEdit(product);
    setModalOpen(true);
  };

  const handleCloneProduct = (product) => {
    const newProduct = { ...product, nume: `${product.nume} Copy` };
    delete newProduct.id;
    setProductToEdit(newProduct);
    setModalOpen(true);
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await pb.collection('produse').delete(productId);
      alert('Product deleted successfully!');
      fetchProducts();

      if (selectedProduct && selectedProduct.id === productId) {
        setSelectedProduct(null);
      }
    } catch (error) {
      console.error('Error deleting product:', error);
      alert('Failed to delete the product.');
    }
  };

  const handleSaveProduct = async () => {
    try {
      await fetchProducts();
      setModalOpen(false);

      if (productToEdit && selectedProduct && productToEdit.id === selectedProduct.id) {
        const updatedProduct = await pb.collection('produse').getOne(productToEdit.id);
        setSelectedProduct(updatedProduct);
      }
    } catch (error) {
      console.error('Error saving product:', error);
    }
  };

  const handleLogout = () => {
    logout();
    setIsLoggedIn(false);
    setLoginModalOpen(true);
  };

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    fetchProducts();
  };

  if (!isLoggedIn) {
    return (
      <LoginModal
        isOpen={isLoginModalOpen}
        onRequestClose={() => { }}
        onLoginSuccess={handleLoginSuccess}
      />
    );
  }

  return (
    <div className="m-5 p-4">
      <h1 className="text-2xl font-bold mb-4">Preturi Husqvarna - La Gheorghita</h1>
      <button onClick={handleLogout} className="bg-red-500 text-white px-4 py-2 rounded mb-4">
        Logout
      </button>
      <div className="flex space-x-10">
        {/* Product Table */}
        <div className="w-[60%]">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <p className="mt-2 text-sm text-gray-700">
                A table of products with options to edit or delete each entry.
              </p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                type="button"
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleAddProduct}
              >
                Adauga produs
              </button>
            </div>
          </div>
          <ProductTable
            products={products}
            onSelectProduct={handleSelectProduct}
            onEditProduct={handleEditProduct}
            onDeleteProduct={handleDeleteProduct}
            onCloneProduct={handleCloneProduct}
          />
        </div>

        {/* PDF Viewer */}
        <div className="w-[40%]">
          <ProductPDFViewer product={selectedProduct} />
        </div>
      </div>

      {/* Modal for CRUD Operations */}
      <ProductModal
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}
        product={productToEdit}
        onSave={handleSaveProduct}
      />
    </div>
  );
}

export default App;
